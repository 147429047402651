<template>
  <div class="un-received">
    <el-container class="jietiao">
      <el-main class="main-content">
        <el-container>
          <el-header class="flex-box flex-start text-bold page-title">
            待确认借条
          </el-header>
          <el-main>
            <el-table stripe :data="listData" height="600">
              <el-table-column prop="type" label="对方身份" min-width="160">
                <template slot-scope="scope">
                  {{ getItemByKey(scope.row.type, personTypes, "id").name }}
                </template>
              </el-table-column>

              <el-table-column
                prop="borrowAmount"
                label="借款金额"
                min-width="160"
              ></el-table-column>

              <el-table-column
                prop="yearTransfer"
                label="年化利率"
                min-width="160"
              >
                <template slot-scope="scope">
                  {{ scope.row.yearTransfer }}%
                </template>
              </el-table-column>

              <el-table-column
                prop="purposeLoan"
                label="借款用途"
                min-width="160"
              >
              </el-table-column>

              <el-table-column prop="status" label="状态" min-width="160">
                <template slot-scope="scope">
                  {{ getItemByKey(scope.row.status, statusList, "key").name }}
                </template>
              </el-table-column>

              <el-table-column
                prop="borrowTime"
                label="借款日期"
                min-width="160"
              >
                <template slot-scope="scope">
                  {{ formatDate(scope.row.borrowTime) }}
                </template>
              </el-table-column>

              <el-table-column
                prop="refundTime"
                label="还款日期"
                min-width="160"
              >
                <template slot-scope="scope">
                  {{ formatDate(scope.row.refundTime) }}
                </template>
              </el-table-column>

              <el-table-column label="操作" min-width="240" fixed="right">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="primary"
                    @click="handleDetails(scope.row)"
                  >
                    查看详情
                  </el-button>
                  <el-button size="mini" @click="handleView(scope.row)">
                    查看借款协议
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-main>
        </el-container>
      </el-main>
    </el-container>

    <el-dialog
      center
      width="600px"
      title="借款信息"
      :close-on-click-modal="false"
      :visible.sync="visible"
    >
      <contract-info :id="details.id" v-if="visible" @submit="handleSubmit" />
    </el-dialog>

    <el-dialog
      center
      top="50px"
      width="800px"
      title="借款合同信息"
      :close-on-click-modal="false"
      :visible.sync="visibleContract"
    >
      <contract :id="details.id" v-if="visibleContract" />
    </el-dialog>
  </div>
</template>

<script>
import { listChujieByUnconfirmed } from "@/api/jietiao";
import { formatDate, getItemByKey } from "@/common/utils";
import { personTypes, statusList } from "@/common/const";
import ContractInfo from "../jietiao/contract-info.vue";
import Contract from "../jietiao/contract.vue";

export default {
  components: {
    ContractInfo,
    Contract,
  },
  data() {
    return {
      visible: false,
      personTypes,
      statusList,
      listData: [],
      details: {},
      visibleContract: false,
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  created() {
    this.$store.dispatch("loadConfig").then(() => {
      this.loadData();
    });
  },
  methods: {
    getItemByKey,
    formatDate(date) {
      return formatDate(date, "y-m-d");
    },
    loadData() {
      this.listData = [];
      listChujieByUnconfirmed(this.userInfo.id).then((res) => {
        this.listData = res.data.chujies;
      });
    },
    handleDetails(data) {
      this.visible = true;
      this.details = data || {};
    },
    handleSubmit() {
      this.loadData();
      this.visible = false;
    },
    handleView(data) {
      this.details = data;
      this.visibleContract = true;
    },
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>

<style lang="less">
.un-received {
}
</style>
